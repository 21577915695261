import * as actionTypes from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { Achievement, AchievementResults } from '../../domain/Achievement';
import { ListResults } from '../../common/List/List';
import { AchievementActionTypes } from './actionTypes';

export type AchievementStateType = {
  achievementCreateLoading: boolean;
  achievementCreateError: HttpError;
  achievementCreateSuccess: boolean;
  achievementUpdateLoading: boolean;
  achievementUpdateError: HttpError;
  achievementUpdateSuccess: boolean;
  achievementDeleteLoading: boolean;
  achievementDeleteError: HttpError;
  achievementDeleteSuccess: boolean;
  achievementsList: ListResults<Achievement> | null;
  achievementsLoading: boolean;
  achievementsError: HttpError;
  achievementsUpdateNeeded: boolean;
  allAchievementsList: ListResults<Achievement> | null;
  allAchievementsLoading: boolean;
  allAchievementsError: HttpError;
  exportAchievements: Achievement[] | null;
  exportAchievementsLoading: boolean;
  exportAchievementsError: HttpError;
  exportAchievementsPdfError: HttpError;
  exportAchievementsPdfLoading: boolean;
  achievementResults: AchievementResults | null;
  achievementResultsLoading: boolean;
  achievementResultsError: HttpError;
};

export type AchievementActionType = AchievementStateType & {
  type: AchievementActionTypes;
};

export const initialState: AchievementStateType = {
  achievementCreateLoading: false,
  achievementCreateError: null,
  achievementCreateSuccess: false,
  achievementUpdateLoading: false,
  achievementUpdateError: null,
  achievementUpdateSuccess: false,
  achievementDeleteLoading: false,
  achievementDeleteError: null,
  achievementDeleteSuccess: false,
  achievementsUpdateNeeded: false,
  achievementsList: null,
  achievementsError: null,
  achievementsLoading: false,
  allAchievementsError: null,
  allAchievementsLoading: false,
  allAchievementsList: null,
  exportAchievements: null,
  exportAchievementsLoading: false,
  exportAchievementsError: null,
  exportAchievementsPdfError: null,
  exportAchievementsPdfLoading: false,
  achievementResultsLoading: false,
  achievementResults: null,
  achievementResultsError: null,
};

const fetchAchievementsStart = (
  state: AchievementStateType,
): AchievementStateType => ({
  ...state,
  achievementsLoading: true,
  achievementsUpdateNeeded: false,
});

const fetchAchievementsSuccess = (
  state: AchievementStateType,
  action: AchievementActionType,
): AchievementStateType => ({
  ...state,
  achievementsLoading: false,
  achievementsError: null,
  achievementsList: action.achievementsList,
});

const fetchAchievementsFail = (
  state: AchievementStateType,
  action: AchievementActionType,
): AchievementStateType => ({
  ...state,
  achievementsLoading: false,
  achievementsError: action.achievementsError,
});

const fetchAllAchievementsStart = (
  state: AchievementStateType,
): AchievementStateType => ({
  ...state,
  allAchievementsLoading: true,
});

const fetchAllAchievementsSuccess = (
  state: AchievementStateType,
  action: AchievementActionType,
): AchievementStateType => ({
  ...state,
  allAchievementsLoading: false,
  allAchievementsError: null,
  allAchievementsList: action.allAchievementsList,
});

const fetchAllAchievementsFail = (
  state: AchievementStateType,
  action: AchievementActionType,
): AchievementStateType => ({
  ...state,
  allAchievementsLoading: false,
  allAchievementsError: action.allAchievementsError,
});

const fetchAllAchievementResultsStart = (
  state: AchievementStateType,
): AchievementStateType => ({
  ...state,
  achievementResultsLoading: true,
});

const fetchAllAchievementResultsSuccess = (
  state: AchievementStateType,
  action: AchievementActionType,
): AchievementStateType => ({
  ...state,
  achievementResultsLoading: false,
  achievementResultsError: null,
  achievementResults: action.achievementResults,
});

const fetchAllAchievementResultsFail = (
  state: AchievementStateType,
  action: AchievementActionType,
): AchievementStateType => ({
  ...state,
  achievementResultsLoading: false,
  achievementResultsError: action.achievementResultsError,
});

const createAchievementStart = (
  state: AchievementStateType,
): AchievementStateType => ({
  ...state,
  achievementCreateLoading: true,
});

const createAchievementSuccess = (
  state: AchievementStateType,
  action: AchievementActionType,
): AchievementStateType => ({
  ...state,
  achievementCreateLoading: false,
  achievementCreateError: null,
  achievementCreateSuccess: true,
  achievementsUpdateNeeded: true,
});

const createAchievementFail = (
  state: AchievementStateType,
  action: AchievementActionType,
): AchievementStateType => ({
  ...state,
  achievementCreateLoading: false,
  achievementCreateError: action.achievementCreateError,
});

const updateAchievementStart = (
  state: AchievementStateType,
): AchievementStateType => ({
  ...state,
  achievementUpdateLoading: true,
  achievementUpdateSuccess: false,
});

const updateAchievementSuccess = (
  state: AchievementStateType,
): AchievementStateType => ({
  ...state,
  achievementUpdateLoading: false,
  achievementUpdateError: null,
  achievementUpdateSuccess: true,
  achievementsUpdateNeeded: true,
});

const updateAchievementFail = (
  state: AchievementStateType,
  action: AchievementActionType,
): AchievementStateType => ({
  ...state,
  achievementUpdateLoading: false,
  achievementUpdateError: action.achievementUpdateError,
});

const deleteAchievementStart = (
  state: AchievementStateType,
): AchievementStateType => ({
  ...state,
  achievementDeleteLoading: true,
});

const deleteAchievementSuccess = (
  state: AchievementStateType,
): AchievementStateType => ({
  ...state,
  achievementDeleteLoading: false,
  achievementDeleteError: null,
  achievementDeleteSuccess: true,
  achievementsUpdateNeeded: true,
});

const deleteAchievementFail = (
  state: AchievementStateType,
  action: AchievementActionType,
): AchievementStateType => ({
  ...state,
  achievementDeleteLoading: false,
  achievementDeleteError: action.achievementDeleteError,
});

const exportAchievementsStart = (
  state: AchievementStateType,
): AchievementStateType => ({
  ...state,
  exportAchievementsLoading: true,
});

const exportAchievementsSuccess = (
  state: AchievementStateType,
  action: AchievementActionType,
): AchievementStateType => ({
  ...state,
  exportAchievementsLoading: false,
  exportAchievementsError: null,
  exportAchievements: action.exportAchievements,
});

const exportAchievementsFail = (
  state: AchievementStateType,
  action: AchievementActionType,
): AchievementStateType => ({
  ...state,
  exportAchievementsLoading: false,
  exportAchievementsError: action.exportAchievementsError,
});

const exportAchievementsPdfStart = (
  state: AchievementStateType,
): AchievementStateType => ({
  ...state,
  exportAchievementsPdfLoading: true,
  exportAchievementsPdfError: null,
});

const exportAchievementsPdfSuccess = (
  state: AchievementStateType,
): AchievementStateType => ({
  ...state,
  exportAchievementsPdfLoading: false,
  exportAchievementsPdfError: null,
});

const exportAchievementsPdfFail = (
  state: AchievementStateType,
  action: AchievementActionType,
): AchievementStateType => ({
  ...state,
  exportAchievementsPdfLoading: false,
  exportAchievementsPdfError: action.exportAchievementsPdfError,
});

const resetPartiallyAchievementStore = (
  state: AchievementStateType,
): AchievementStateType => ({
  ...initialState,
  achievementsList: state.achievementsList,
  allAchievementsList: state.allAchievementsList,
});

const resetAchievementStore = (): AchievementStateType => ({
  ...initialState,
});

const completelyResetAchievementStore = (): AchievementStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: AchievementActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_ACHIEVEMENT_START:
      return fetchAchievementsStart(state);
    case actionTypes.FETCH_ACHIEVEMENT_SUCCESS:
      return fetchAchievementsSuccess(state, action);
    case actionTypes.FETCH_ACHIEVEMENT_FAIL:
      return fetchAchievementsFail(state, action);
    case actionTypes.FETCH_ALL_ACHIEVEMENT_START:
      return fetchAllAchievementsStart(state);
    case actionTypes.FETCH_ALL_ACHIEVEMENT_SUCCESS:
      return fetchAllAchievementsSuccess(state, action);
    case actionTypes.FETCH_ALL_ACHIEVEMENT_FAIL:
      return fetchAllAchievementsFail(state, action);
    case actionTypes.FETCH_ALL_ACHIEVEMENT_RESULTS_START:
      return fetchAllAchievementResultsStart(state);
    case actionTypes.FETCH_ALL_ACHIEVEMENT_RESULTS_SUCCESS:
      return fetchAllAchievementResultsSuccess(state, action);
    case actionTypes.FETCH_ALL_ACHIEVEMENT_RESULTS_FAIL:
      return fetchAllAchievementResultsFail(state, action);
    case actionTypes.CREATE_ACHIEVEMENT_START:
      return createAchievementStart(state);
    case actionTypes.CREATE_ACHIEVEMENT_SUCCESS:
      return createAchievementSuccess(state, action);
    case actionTypes.CREATE_ACHIEVEMENT_FAIL:
      return createAchievementFail(state, action);
    case actionTypes.UPDATE_ACHIEVEMENT_START:
      return updateAchievementStart(state);
    case actionTypes.UPDATE_ACHIEVEMENT_SUCCESS:
      return updateAchievementSuccess(state);
    case actionTypes.UPDATE_ACHIEVEMENT_FAIL:
      return updateAchievementFail(state, action);
    case actionTypes.DELETE_ACHIEVEMENT_START:
      return deleteAchievementStart(state);
    case actionTypes.DELETE_ACHIEVEMENT_SUCCESS:
      return deleteAchievementSuccess(state);
    case actionTypes.DELETE_ACHIEVEMENT_FAIL:
      return deleteAchievementFail(state, action);
    case actionTypes.EXPORT_ACHIEVEMENTS_START:
      return exportAchievementsStart(state);
    case actionTypes.EXPORT_ACHIEVEMENTS_SUCCESS:
      return exportAchievementsSuccess(state, action);
    case actionTypes.EXPORT_ACHIEVEMENTS_FAIL:
      return exportAchievementsFail(state, action);
    case actionTypes.EXPORT_ACHIEVEMENTS_PDF_START:
      return exportAchievementsPdfStart(state);
    case actionTypes.EXPORT_ACHIEVEMENTS_PDF_SUCCESS:
      return exportAchievementsPdfSuccess(state);
    case actionTypes.EXPORT_ACHIEVEMENTS_PDF_FAIL:
      return exportAchievementsPdfFail(state, action);
    case actionTypes.RESET_PARTIALLY_ACHIEVEMENT_STORE:
      return resetPartiallyAchievementStore(state);
    case actionTypes.RESET_ACHIEVEMENT_STORE:
      return resetAchievementStore();
    case actionTypes.LOGOUT:
      return completelyResetAchievementStore();
    default:
      return state;
  }
};

export default reducer;
