import * as actionTypes from './actionTypes';
import { WorkTimeActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { WorkTime } from '../../domain/WorkTime';
import { ListResults } from '../../common/List/List';
import { Attendance } from '../../domain/Attendance';

export type WorkTimeStateType = {
  workTimeCreateLoading: boolean;
  workTimeCreateError: HttpError;
  workTimeCreateSuccess: boolean;
  createdWorkTime: WorkTime | null;
  workTimeUpdateLoading: boolean;
  workTimeUpdateError: HttpError;
  workTimeUpdateSuccess: boolean;
  workTimeDeleteLoading: boolean;
  workTimeDeleteError: HttpError;
  workTimeDeleteSuccess: boolean;
  workTimesList: ListResults<WorkTime> | null;
  workTimesLoading: boolean;
  workTimesError: HttpError;
  workTimesUpdateNeeded: boolean;
  allWorkTimeAttendances: WorkTime[] | null;
  allWorkTimeAttendancesLoading: boolean;
  allWorkTimeAttendancesError: HttpError;
  allWorkTimes: WorkTime[] | null;
  allWorkTimesLoading: boolean;
  allWorkTimesError: HttpError;
  workTime: WorkTime | null;
  workTimeError: HttpError;
  workTimeLoading: boolean;
  weeklyWorkTimes: WorkTime[] | null;
  weeklyWorkTimesLoading: boolean;
  weeklyWorkTimesError: HttpError;
  updateWeeklyWorkTimes: boolean;
  toggleWorkTimeStatusLoading: boolean;
  toggleWorkTimeStatusError: HttpError;
  downloadTestingProtocolError: HttpError;
  downloadTestingProtocolLoading: boolean;
  generateTestingResultsLoading: boolean;
  generateTestingResultsError: HttpError;
};

export type WorkTimeActionType = WorkTimeStateType & {
  type: WorkTimeActionTypes;
  attendanceToggleValue: Attendance | null;
  attendanceId: number | null;
};

export const initialState: WorkTimeStateType = {
  workTimeCreateLoading: false,
  workTimeCreateError: null,
  workTimeCreateSuccess: false,
  createdWorkTime: null,
  workTimeUpdateLoading: false,
  workTimeUpdateError: null,
  workTimeUpdateSuccess: false,
  workTimeDeleteLoading: false,
  workTimeDeleteError: null,
  workTimeDeleteSuccess: false,
  workTimesUpdateNeeded: false,
  workTimesList: null,
  workTimesError: null,
  workTimesLoading: false,
  allWorkTimeAttendances: null,
  allWorkTimeAttendancesError: null,
  allWorkTimeAttendancesLoading: false,
  allWorkTimesError: null,
  allWorkTimesLoading: false,
  allWorkTimes: null,
  workTimeError: null,
  workTime: null,
  workTimeLoading: false,
  weeklyWorkTimesError: null,
  weeklyWorkTimes: null,
  weeklyWorkTimesLoading: false,
  updateWeeklyWorkTimes: false,
  toggleWorkTimeStatusLoading: false,
  toggleWorkTimeStatusError: null,
  downloadTestingProtocolError: null,
  downloadTestingProtocolLoading: false,
  generateTestingResultsLoading: false,
  generateTestingResultsError: null,
};

const fetchWorkTimesStart = (state: WorkTimeStateType): WorkTimeStateType => ({
  ...state,
  workTimesLoading: true,
  workTimesUpdateNeeded: false,
});

const fetchWorkTimesSuccess = (
  state: WorkTimeStateType,
  action: WorkTimeActionType,
): WorkTimeStateType => ({
  ...state,
  workTimesLoading: false,
  workTimesError: null,
  workTimesList: action.workTimesList,
});

const fetchWorkTimesFail = (
  state: WorkTimeStateType,
  action: WorkTimeActionType,
): WorkTimeStateType => ({
  ...state,
  workTimesLoading: false,
  workTimesError: action.workTimesError,
});

const fetchWeeklyWorkTimesStart = (
  state: WorkTimeStateType,
): WorkTimeStateType => ({
  ...state,
  weeklyWorkTimesLoading: true,
  updateWeeklyWorkTimes: false,
});

const fetchWeeklyWorkTimesSuccess = (
  state: WorkTimeStateType,
  action: WorkTimeActionType,
): WorkTimeStateType => ({
  ...state,
  weeklyWorkTimesLoading: false,
  weeklyWorkTimesError: null,
  weeklyWorkTimes: action.weeklyWorkTimes,
});

const fetchWeeklyWorkTimesFail = (
  state: WorkTimeStateType,
  action: WorkTimeActionType,
): WorkTimeStateType => ({
  ...state,
  weeklyWorkTimesLoading: false,
  weeklyWorkTimesError: action.weeklyWorkTimesError,
});

const generateTestingResultsStart = (
  state: WorkTimeStateType,
): WorkTimeStateType => ({
  ...state,
  generateTestingResultsLoading: true,
});

const generateTestingResultsSuccess = (
  state: WorkTimeStateType,
): WorkTimeStateType => ({
  ...state,
  generateTestingResultsLoading: false,
  generateTestingResultsError: null,
});

const generateTestingResultsFail = (
  state: WorkTimeStateType,
  action: WorkTimeActionType,
): WorkTimeStateType => ({
  ...state,
  generateTestingResultsLoading: false,
  generateTestingResultsError: action.generateTestingResultsError,
});

const fetchWorkTimeStart = (state: WorkTimeStateType): WorkTimeStateType => ({
  ...state,
  workTimeLoading: true,
  workTimesUpdateNeeded: false,
});

const fetchWorkTimeSuccess = (
  state: WorkTimeStateType,
  action: WorkTimeActionType,
): WorkTimeStateType => ({
  ...state,
  workTimeLoading: false,
  workTimeError: null,
  workTime: action.workTime,
});

const fetchWorkTimeFail = (
  state: WorkTimeStateType,
  action: WorkTimeActionType,
): WorkTimeStateType => ({
  ...state,
  workTimeLoading: false,
  workTimeError: action.workTimeError,
});

const fetchAllWorkTimesStart = (
  state: WorkTimeStateType,
): WorkTimeStateType => ({
  ...state,
  allWorkTimesLoading: true,
});

const fetchAllWorkTimesSuccess = (
  state: WorkTimeStateType,
  action: WorkTimeActionType,
): WorkTimeStateType => ({
  ...state,
  allWorkTimesLoading: false,
  allWorkTimesError: null,
  allWorkTimes: action.allWorkTimes,
});

const fetchAllWorkTimesFail = (
  state: WorkTimeStateType,
  action: WorkTimeActionType,
): WorkTimeStateType => ({
  ...state,
  allWorkTimesLoading: false,
  allWorkTimesError: action.allWorkTimesError,
});

const fetchWorkTimeAttendancesStart = (
  state: WorkTimeStateType,
): WorkTimeStateType => ({
  ...state,
  allWorkTimeAttendancesLoading: true,
});

const fetchWorkTimeAttendancesSuccess = (
  state: WorkTimeStateType,
  action: WorkTimeActionType,
): WorkTimeStateType => ({
  ...state,
  allWorkTimeAttendances: action.allWorkTimeAttendances,
  allWorkTimeAttendancesLoading: false,
  allWorkTimeAttendancesError: null,
});

const fetchWorkTimeAttendancesFail = (
  state: WorkTimeStateType,
  action: WorkTimeActionType,
): WorkTimeStateType => ({
  ...state,
  allWorkTimeAttendancesError: action.allWorkTimeAttendancesError,
  allWorkTimeAttendancesLoading: false,
});

const createWorkTimeStart = (state: WorkTimeStateType): WorkTimeStateType => ({
  ...state,
  workTimeCreateLoading: true,
});

const createWorkTimeSuccess = (
  state: WorkTimeStateType,
  action: WorkTimeActionType,
): WorkTimeStateType => ({
  ...state,
  workTimeCreateLoading: false,
  workTimeCreateError: null,
  workTimeCreateSuccess: true,
  createdWorkTime: action.createdWorkTime,
  workTimesUpdateNeeded: true,
});

const createWorkTimeFail = (
  state: WorkTimeStateType,
  action: WorkTimeActionType,
): WorkTimeStateType => ({
  ...state,
  workTimeCreateLoading: false,
  workTimeCreateError: action.workTimeCreateError,
});

const updateWorkTimeStart = (state: WorkTimeStateType): WorkTimeStateType => ({
  ...state,
  workTimeUpdateLoading: true,
  workTimeUpdateSuccess: false,
});

const updateWorkTimeSuccess = (
  state: WorkTimeStateType,
): WorkTimeStateType => ({
  ...state,
  workTimeUpdateLoading: false,
  workTimeUpdateError: null,
  workTimeUpdateSuccess: true,
  workTimesUpdateNeeded: true,
});

const updateWorkTimeFail = (
  state: WorkTimeStateType,
  action: WorkTimeActionType,
): WorkTimeStateType => ({
  ...state,
  workTimeUpdateLoading: false,
  workTimeUpdateError: action.workTimeUpdateError,
});

const toggleWorkTimeStatusStart = (
  state: WorkTimeStateType,
): WorkTimeStateType => ({
  ...state,
  toggleWorkTimeStatusLoading: true,
});

const toggleWorkTimeStatusSuccess = (
  state: WorkTimeStateType,
): WorkTimeStateType => ({
  ...state,
  toggleWorkTimeStatusLoading: false,
  toggleWorkTimeStatusError: null,
  updateWeeklyWorkTimes: true,
});

const toggleWorkTimeStatusFail = (
  state: WorkTimeStateType,
  action: WorkTimeActionType,
): WorkTimeStateType => ({
  ...state,
  toggleWorkTimeStatusLoading: false,
  toggleWorkTimeStatusError: action.toggleWorkTimeStatusError,
});

const downloadTestingProtocolStart = (
  state: WorkTimeStateType,
): WorkTimeStateType => ({
  ...state,
  downloadTestingProtocolLoading: true,
});

const downloadTestingProtocolSuccess = (
  state: WorkTimeStateType,
): WorkTimeStateType => ({
  ...state,
  downloadTestingProtocolLoading: false,
  downloadTestingProtocolError: null,
});

const downloadTestingProtocolFail = (
  state: WorkTimeStateType,
  action: WorkTimeActionType,
): WorkTimeStateType => ({
  ...state,
  downloadTestingProtocolLoading: false,
  downloadTestingProtocolError: action.downloadTestingProtocolError,
});

const deleteWorkTimeStart = (state: WorkTimeStateType): WorkTimeStateType => ({
  ...state,
  workTimeDeleteLoading: true,
});

const deleteWorkTimeSuccess = (
  state: WorkTimeStateType,
): WorkTimeStateType => ({
  ...state,
  workTimeDeleteLoading: false,
  workTimeDeleteError: null,
  workTimeDeleteSuccess: true,
  workTimesUpdateNeeded: true,
});

const deleteWorkTimeFail = (
  state: WorkTimeStateType,
  action: WorkTimeActionType,
): WorkTimeStateType => ({
  ...state,
  workTimeDeleteLoading: false,
  workTimeDeleteError: action.workTimeDeleteError,
});

const resetPartiallyWorkTimeStore = (
  state: WorkTimeStateType,
): WorkTimeStateType => ({
  ...initialState,
  workTimesList: state.workTimesList,
});

const setAttendanceToggleValue = (
  state: WorkTimeStateType,
  action: WorkTimeActionType,
): WorkTimeStateType => ({
  ...state,
  allWorkTimeAttendances: state.allWorkTimeAttendances
    ? state.allWorkTimeAttendances.map((workTime) => {
        return {
          ...workTime,
          attendances: workTime.attendances.map((attendance) => {
            if (
              action.attendanceToggleValue &&
              attendance.id === action.attendanceToggleValue.id
            ) {
              attendance.isAttended = action.attendanceToggleValue.isAttended;
            }

            return attendance;
          }),
        };
      })
    : state.allWorkTimeAttendances,
});

const setAttendanceDeleteValue = (
  state: WorkTimeStateType,
  action: WorkTimeActionType,
): WorkTimeStateType => ({
  ...state,
  allWorkTimeAttendances: state.allWorkTimeAttendances
    ? state.allWorkTimeAttendances.map((workTime) => {
        return {
          ...workTime,
          attendances: workTime.attendances.filter(
            (attendance) => attendance.id !== action.attendanceId,
          ),
        };
      })
    : state.allWorkTimeAttendances,
});

const refreshWorkTimeStore = (): WorkTimeStateType => ({
  ...initialState,
  workTimesUpdateNeeded: true,
});

const resetWorkTimeStore = (): WorkTimeStateType => ({
  ...initialState,
});

const completelyResetWorkTimeStore = (): WorkTimeStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: WorkTimeActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_WORK_TIMES_START:
      return fetchWorkTimesStart(state);
    case actionTypes.FETCH_WORK_TIMES_SUCCESS:
      return fetchWorkTimesSuccess(state, action);
    case actionTypes.FETCH_WORK_TIMES_FAIL:
      return fetchWorkTimesFail(state, action);
    case actionTypes.FETCH_WEEKLY_WORK_TIMES_START:
      return fetchWeeklyWorkTimesStart(state);
    case actionTypes.FETCH_WEEKLY_WORK_TIMES_SUCCESS:
      return fetchWeeklyWorkTimesSuccess(state, action);
    case actionTypes.FETCH_WEEKLY_WORK_TIMES_FAIL:
      return fetchWeeklyWorkTimesFail(state, action);
    case actionTypes.FETCH_WORK_TIME_START:
      return fetchWorkTimeStart(state);
    case actionTypes.FETCH_WORK_TIME_SUCCESS:
      return fetchWorkTimeSuccess(state, action);
    case actionTypes.FETCH_WORK_TIME_FAIL:
      return fetchWorkTimeFail(state, action);
    case actionTypes.CREATE_WORK_TIME_START:
      return createWorkTimeStart(state);
    case actionTypes.CREATE_WORK_TIME_SUCCESS:
      return createWorkTimeSuccess(state, action);
    case actionTypes.CREATE_WORK_TIME_FAIL:
      return createWorkTimeFail(state, action);
    case actionTypes.UPDATE_WORK_TIME_START:
      return updateWorkTimeStart(state);
    case actionTypes.UPDATE_WORK_TIME_SUCCESS:
      return updateWorkTimeSuccess(state);
    case actionTypes.UPDATE_WORK_TIME_FAIL:
      return updateWorkTimeFail(state, action);
    case actionTypes.DELETE_WORK_TIME_START:
      return deleteWorkTimeStart(state);
    case actionTypes.DELETE_WORK_TIME_SUCCESS:
      return deleteWorkTimeSuccess(state);
    case actionTypes.DELETE_WORK_TIME_FAIL:
      return deleteWorkTimeFail(state, action);
    case actionTypes.FETCH_WORK_TIME_ATTENDANCES_START:
      return fetchWorkTimeAttendancesStart(state);
    case actionTypes.FETCH_WORK_TIME_ATTENDANCES_SUCCESS:
      return fetchWorkTimeAttendancesSuccess(state, action);
    case actionTypes.FETCH_WORK_TIME_ATTENDANCES_FAIL:
      return fetchWorkTimeAttendancesFail(state, action);
    case actionTypes.FETCH_ALL_WORK_TIMES_START:
      return fetchAllWorkTimesStart(state);
    case actionTypes.FETCH_ALL_WORK_TIMES_SUCCESS:
      return fetchAllWorkTimesSuccess(state, action);
    case actionTypes.FETCH_ALL_WORK_TIMES_FAIL:
      return fetchAllWorkTimesFail(state, action);
    case actionTypes.SET_ATTENDANCE_TOGGLE_VALUE:
      return setAttendanceToggleValue(state, action);
    case actionTypes.TOGGLE_WORK_TIME_STATUS_START:
      return toggleWorkTimeStatusStart(state);
    case actionTypes.TOGGLE_WORK_TIME_STATUS_SUCCESS:
      return toggleWorkTimeStatusSuccess(state);
    case actionTypes.TOGGLE_WORK_TIME_STATUS_FAIL:
      return toggleWorkTimeStatusFail(state, action);
    case actionTypes.DOWNLOAD_TESTING_PROTOCOL_START:
      return downloadTestingProtocolStart(state);
    case actionTypes.DOWNLOAD_TESTING_PROTOCOL_SUCCESS:
      return downloadTestingProtocolSuccess(state);
    case actionTypes.DOWNLOAD_TESTING_PROTOCOL_FAIL:
      return downloadTestingProtocolFail(state, action);
    case actionTypes.SET_ATTENDANCE_DELETE_VALUE:
      return setAttendanceDeleteValue(state, action);
    case actionTypes.GENERATE_TESTING_RESULTS_START:
      return generateTestingResultsStart(state);
    case actionTypes.GENERATE_TESTING_RESULTS_SUCCESS:
      return generateTestingResultsSuccess(state);
    case actionTypes.GENERATE_TESTING_RESULTS_FAIL:
      return generateTestingResultsFail(state, action);
    case actionTypes.RESET_PARTIALLY_WORK_TIME_STORE:
      return resetPartiallyWorkTimeStore(state);
    case actionTypes.REFRESH_WORK_TIME_STORE:
      return refreshWorkTimeStore();
    case actionTypes.RESET_WORK_TIME_STORE:
      return resetWorkTimeStore();
    case actionTypes.LOGOUT:
      return completelyResetWorkTimeStore();
    default:
      return state;
  }
};

export default reducer;
