export const FETCH_WORK_TIMES_START = 'FETCH_WORK_TIMES_START';
export const FETCH_WORK_TIMES_SUCCESS = 'FETCH_WORK_TIMES_SUCCESS';
export const FETCH_WORK_TIMES_FAIL = 'FETCH_WORK_TIMES_FAIL';

export const FETCH_WEEKLY_WORK_TIMES_START = 'FETCH_WEEKLY_WORK_TIMES_START';
export const FETCH_WEEKLY_WORK_TIMES_SUCCESS =
  'FETCH_WEEKLY_WORK_TIMES_SUCCESS';
export const FETCH_WEEKLY_WORK_TIMES_FAIL = 'FETCH_WEEKLY_WORK_TIMES_FAIL';

export const FETCH_WORK_TIME_START = 'FETCH_WORK_TIME_START';
export const FETCH_WORK_TIME_SUCCESS = 'FETCH_WORK_TIME_SUCCESS';
export const FETCH_WORK_TIME_FAIL = 'FETCH_WORK_TIME_FAIL';

export const CREATE_WORK_TIME_START = 'CREATE_WORK_TIME_START';
export const CREATE_WORK_TIME_SUCCESS = 'CREATE_WORK_TIME_SUCCESS';
export const CREATE_WORK_TIME_FAIL = 'CREATE_WORK_TIME_FAIL';

export const UPDATE_WORK_TIME_START = 'UPDATE_WORK_TIME_START';
export const UPDATE_WORK_TIME_SUCCESS = 'UPDATE_WORK_TIME_SUCCESS';
export const UPDATE_WORK_TIME_FAIL = 'UPDATE_WORK_TIME_FAIL';

export const DELETE_WORK_TIME_START = 'DELETE_WORK_TIME_START';
export const DELETE_WORK_TIME_SUCCESS = 'DELETE_WORK_TIME_SUCCESS';
export const DELETE_WORK_TIME_FAIL = 'DELETE_WORK_TIME_FAIL';

export const FETCH_WORK_TIME_ATTENDANCES_START =
  'FETCH_WORK_TIME_ATTENDANCES_START';
export const FETCH_WORK_TIME_ATTENDANCES_SUCCESS =
  'FETCH_WORK_TIME_ATTENDANCES_SUCCESS';
export const FETCH_WORK_TIME_ATTENDANCES_FAIL =
  'FETCH_WORK_TIME_ATTENDANCES_SUCCESS';

export const GENERATE_TESTING_RESULTS_START = 'GENERATE_TESTING_RESULTS_START';
export const GENERATE_TESTING_RESULTS_SUCCESS =
  'GENERATE_TESTING_RESULTS_SUCCESS';
export const GENERATE_TESTING_RESULTS_FAIL = 'GENERATE_TESTING_RESULTS_FAIL';

export const FETCH_ALL_WORK_TIMES_START = 'FETCH_ALL_WORK_TIMES_START';
export const FETCH_ALL_WORK_TIMES_SUCCESS = 'FETCH_ALL_WORK_TIMES_SUCCESS';
export const FETCH_ALL_WORK_TIMES_FAIL = 'FETCH_ALL_WORK_TIMES_FAIL';

export const DOWNLOAD_TESTING_PROTOCOL_START =
  'DOWNLOAD_TESTING_PROTOCOL_START';
export const DOWNLOAD_TESTING_PROTOCOL_SUCCESS =
  'DOWNLOAD_TESTING_PROTOCOL_SUCCESS';
export const DOWNLOAD_TESTING_PROTOCOL_FAIL = 'DOWNLOAD_TESTING_PROTOCOL_FAIL';

export const TOGGLE_WORK_TIME_STATUS_START = 'TOGGLE_WORK_TIME_STATUS_START';
export const TOGGLE_WORK_TIME_STATUS_SUCCESS =
  'TOGGLE_WORK_TIME_STATUS_SUCCESS';
export const TOGGLE_WORK_TIME_STATUS_FAIL = 'TOGGLE_WORK_TIME_STATUS_FAIL';

export const SET_ATTENDANCE_TOGGLE_VALUE = 'SET_ATTENDANCE_TOGGLE_VALUE';
export const SET_ATTENDANCE_DELETE_VALUE = 'SET_ATTENDANCE_DELETE_VALUE';
export const RESET_WORK_TIME_STORE = 'RESET_WORK_TIME_STORE';
export const RESET_PARTIALLY_WORK_TIME_STORE =
  'RESET_PARTIALLY_WORK_TIME_STORE';
export const REFRESH_WORK_TIME_STORE = 'REFRESH_WORK_TIME_STORE';

export const LOGOUT = 'LOGOUT';

export type WorkTimeActionTypes =
  | typeof FETCH_WORK_TIMES_START
  | typeof FETCH_WORK_TIMES_SUCCESS
  | typeof FETCH_WORK_TIMES_FAIL
  | typeof FETCH_WEEKLY_WORK_TIMES_START
  | typeof FETCH_WEEKLY_WORK_TIMES_SUCCESS
  | typeof FETCH_WEEKLY_WORK_TIMES_FAIL
  | typeof FETCH_WORK_TIME_START
  | typeof FETCH_WORK_TIME_SUCCESS
  | typeof FETCH_WORK_TIME_FAIL
  | typeof GENERATE_TESTING_RESULTS_START
  | typeof GENERATE_TESTING_RESULTS_SUCCESS
  | typeof GENERATE_TESTING_RESULTS_FAIL
  | typeof TOGGLE_WORK_TIME_STATUS_START
  | typeof TOGGLE_WORK_TIME_STATUS_SUCCESS
  | typeof TOGGLE_WORK_TIME_STATUS_FAIL
  | typeof CREATE_WORK_TIME_START
  | typeof CREATE_WORK_TIME_SUCCESS
  | typeof CREATE_WORK_TIME_FAIL
  | typeof UPDATE_WORK_TIME_START
  | typeof UPDATE_WORK_TIME_SUCCESS
  | typeof UPDATE_WORK_TIME_FAIL
  | typeof DELETE_WORK_TIME_START
  | typeof DELETE_WORK_TIME_SUCCESS
  | typeof DELETE_WORK_TIME_FAIL
  | typeof FETCH_WORK_TIME_ATTENDANCES_START
  | typeof FETCH_WORK_TIME_ATTENDANCES_SUCCESS
  | typeof FETCH_WORK_TIME_ATTENDANCES_FAIL
  | typeof FETCH_ALL_WORK_TIMES_START
  | typeof FETCH_ALL_WORK_TIMES_SUCCESS
  | typeof FETCH_ALL_WORK_TIMES_FAIL
  | typeof DOWNLOAD_TESTING_PROTOCOL_START
  | typeof DOWNLOAD_TESTING_PROTOCOL_SUCCESS
  | typeof DOWNLOAD_TESTING_PROTOCOL_FAIL
  | typeof SET_ATTENDANCE_TOGGLE_VALUE
  | typeof SET_ATTENDANCE_DELETE_VALUE
  | typeof RESET_WORK_TIME_STORE
  | typeof RESET_PARTIALLY_WORK_TIME_STORE
  | typeof REFRESH_WORK_TIME_STORE
  | typeof LOGOUT;
