import React, { Suspense, useEffect } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { connect } from 'react-redux';
import Loader from '../../common/Loader/Loader';
import { StoreState } from '../StoreProvider/StoreProvider';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import * as authService from '../../store/auth/service';
import * as userService from '../../store/user/service';
import { routes } from './routes';
import Layout from '../../common/Layout/Layout';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import { JwtToken } from '../Axios/axios-instance';
import { logout, selectLocale } from '../../store/auth/actions';
import * as languageService from '../../store/language/service';
import { Language } from '../../domain/Language';
import { DEFAULT_LOCALE } from '../constants';
import { Locale } from '../../domain/Translation';
import { IntlProvider } from 'react-intl';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '../../common/ErrorFallback/ErrorFallback';
import { User } from '../../domain/User';
import { Roles } from '../../domain/Role';

const LoginPage = React.lazy(
  () => import('../../pages/Public/Auth/LoginPage/LoginPage'),
);

const RegistrationPage = React.lazy(
  () => import('../../pages/Public/Auth/RegistrationPage/RegistrationPage'),
);

const ForgotPasswordPage = React.lazy(
  () => import('../../pages/Public/Auth/ForgotPasswordPage/ForgotPasswordPage'),
);

const ResetPasswordPage = React.lazy(
  () => import('../../pages/Public/Auth/ResetPasswordPage/ResetPasswordPage'),
);

const UsersListPage = React.lazy(
  () => import('../../pages/Admin/User/UsersListPage/UsersListPage'),
);

const UserCreatePage = React.lazy(
  () => import('../../pages/Admin/User/UserCreatePage/UserCreatePage'),
);

const UserEditPage = React.lazy(
  () => import('../../pages/Admin/User/UserEditPage/UserEditPage'),
);

const TranslationsPage = React.lazy(
  () =>
    import(
      '../../pages/Admin/Translation/TranslationListPage/TranslationListPage'
    ),
);

const LanguagesListPage = React.lazy(
  () => import('../../pages/Admin/Language/LanguagesListPage'),
);

const BranchListPage = React.lazy(
  () => import('../../pages/Admin/Branch/BranchListPage/BranchListPage'),
);

const BranchEditPage = React.lazy(
  () => import('../../pages/Admin/Branch/BranchEditPage/BranchEditPage'),
);

const WorkTimeListPage = React.lazy(
  () => import('../../pages/Admin/WorkTime/WorkTimeListPage/WorkTimeListPage'),
);

const WorkTimeCreatePage = React.lazy(
  () =>
    import('../../pages/Admin/WorkTime/WorkTimeCreatePage/WorkTimeCreatePage'),
);

const WorkTimeEditPage = React.lazy(
  () => import('../../pages/Admin/WorkTime/WorkTimeEditPage/WorkTimeEditPage'),
);

const EmployeeListPage = React.lazy(
  () => import('../../pages/Admin/Employee/EmployeeListPage/EmployeeListPage'),
);

const EmployeeCreatePage = React.lazy(
  () =>
    import('../../pages/Admin/Employee/EmployeeCreatePage/EmployeeCreatePage'),
);

const EmployeeEditPage = React.lazy(
  () => import('../../pages/Admin/Employee/EmployeeEditPage/EmployeeEditPage'),
);

const AdditionalServiceListPage = React.lazy(
  () =>
    import(
      '../../pages/Admin/AdditionalService/AdditionalServiceListPage/AdditionalServiceListPage'
    ),
);

const AdditionalServiceCreatePage = React.lazy(
  () =>
    import(
      '../../pages/Admin/AdditionalService/AdditionalServiceCreatePage/AdditionalServiceCreatePage'
    ),
);

const AdditionalServiceEditPage = React.lazy(
  () =>
    import(
      '../../pages/Admin/AdditionalService/AdditionalServiceEditPage/AdditionalServiceEditPage'
    ),
);

const ChildListPage = React.lazy(
  () => import('../../pages/Admin/Child/ChildListPage/ChildListPage'),
);

const ParentChildListPage = React.lazy(
  () =>
    import('../../pages/Admin/Child/ParentChildListPage/ParentChildListPage'),
);

const ParentChildCreatePage = React.lazy(
  () =>
    import(
      '../../pages/Admin/Child/ParentChildCreatePage/ParentChildCreatePage'
    ),
);

const ParentChildEditPage = React.lazy(
  () =>
    import('../../pages/Admin/Child/ParentChildEditPage/ParentChildEditPage'),
);

const ChildCreatePage = React.lazy(
  () => import('../../pages/Admin/Child/ChildCreatePage/ChildCreatePage'),
);

const ChildEditPage = React.lazy(
  () => import('../../pages/Admin/Child/ChildEditPage/ChildEditPage'),
);

const AttendancesPage = React.lazy(
  () =>
    import(
      '../../pages/Admin/Attendance/AttendanceListPage/AttendanceListPage'
    ),
);

const AchievementsPage = React.lazy(
  () =>
    import(
      '../../pages/Admin/Achievement/AchievementListPage/AchievementListPage'
    ),
);

const ParentAchievementsPage = React.lazy(
  () =>
    import(
      '../../pages/Admin/Achievement/ParentAchievementPage/ParentAchievementPage'
    ),
);

const TestingFormPage = React.lazy(
  () => import('../../pages/Admin/Achievement/TestingFormPage/TestingFormPage'),
);

const PaymentListPage = React.lazy(
  () => import('../../pages/Admin/Payment/PaymentListPage/PaymentListPage'),
);

const ParentPaymentListPage = React.lazy(
  () =>
    import(
      '../../pages/Admin/Payment/ParentPaymentListPage/ParentPaymentListPage'
    ),
);

const AttendancePackageListPage = React.lazy(
  () =>
    import(
      '../../pages/Admin/AttendancePackage/AttendancePackageListPage/AttendancePackageListPage'
    ),
);

const HomePage = React.lazy(
  () => import('../../pages/Public/HomePage/HomePage'),
);

const ProfilePage = React.lazy(
  () => import('../../pages/Admin/User/ProfilePage/ProfilePage'),
);

const PurchasePage = React.lazy(
  () => import('../../pages/Admin/PurchasePage/PurchasePage'),
);

const ParentAttendanceListPage = React.lazy(
  () =>
    import(
      '../../pages/Admin/Attendance/ParentAttendanceListPage/ParentAttendanceListPage'
    ),
);

const WantedTimeListPage = React.lazy(
  () =>
    import(
      '../../pages/Admin/WantedTime/WantedTimeListPage/WantedTimeListPage'
    ),
);

const SubscribedTimeListPage = React.lazy(
  () =>
    import(
      '../../pages/Admin/WantedTime/SubscribedTimeListPage/SubscribedTimeListPage'
    ),
);

const TimeOffListPage = React.lazy(
  () => import('../../pages/Admin/TimeOff/TimeOffListPage/TimeOffListPage'),
);

const WorkTimeCalendarPage = React.lazy(
  () =>
    import(
      '../../pages/Admin/WorkTime/WorkTimeCalendarPage/WorkTimeCalendarPage'
    ),
);

export type Props = {
  isInitCompleted: boolean;
  isAuthenticated: boolean;
  onTryAutoSignup: () => void;
  isCurrentUserLoading: boolean;
  refreshTokenLoading: boolean;
  onFetchCurrentUser: () => void;
  onRefreshToken: () => void;
  jwtToken: string | null;
  lastActionAt: moment.Moment | null;
  onLogout: () => void;
  onLanguageFetch: (locale: string) => void;
  language: Language | null;
  onSelectLocale: (locale: Locale) => void;
  selectedLocale: Locale;
  currentUser: User | null;
  onLanguagesInit: () => void;
};

export const Router = ({
  isInitCompleted,
  isAuthenticated,
  onTryAutoSignup,
  isCurrentUserLoading,
  onFetchCurrentUser,
  refreshTokenLoading,
  onRefreshToken,
  jwtToken,
  lastActionAt,
  onLogout,
  onLanguageFetch,
  language,
  selectedLocale,
  onSelectLocale,
  currentUser,
  onLanguagesInit,
}: Props) => {
  useEffect(() => {
    if (!jwtToken) {
      return;
    }

    const decodedJson: JwtToken = jwtDecode(jwtToken);

    if (!decodedJson) {
      return;
    }

    const difference = moment.duration(
      moment(decodedJson.exp * 1000).diff(moment()),
    );
    const differenceLastAction = moment.duration(moment().diff(lastActionAt));

    if (
      difference.asMinutes() < 5 &&
      differenceLastAction.asMinutes() < 5 &&
      !refreshTokenLoading
    ) {
      onRefreshToken();
    }

    const timeout = setTimeout(() => {
      onLogout();
    }, difference.asMilliseconds());

    return () => clearTimeout(timeout);
  }, [jwtToken, lastActionAt]);

  useEffect(() => {
    onTryAutoSignup();
  }, []);

  useEffect(() => {
    onFetchCurrentUser();
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      onFetchCurrentUser();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    moment.locale(DEFAULT_LOCALE);
    onLanguageFetch(DEFAULT_LOCALE);
    onLanguagesInit();
    onSelectLocale(DEFAULT_LOCALE);
  }, []);

  const mappedTranslations = language?.translations?.reduce(
    (obj, item) =>
      Object.assign(obj, {
        [item.alias]: item.value ? item.value : item.defaultValue,
      }),
    {},
  );

  const getRoutes = () => {
    if (!isAuthenticated) {
      return (
        <>
          <Route
            path={routes.admin}
            element={<Navigate replace to={routes.login} />}
          />
          <Route path={routes.login} element={<LoginPage />} />
          <Route
            path={routes.registrationConfirmation}
            element={<HomePage />}
          />
          <Route path={routes.registration} element={<RegistrationPage />} />
          <Route
            path={routes.forgotPassword}
            element={<ForgotPasswordPage />}
          />
          <Route path={routes.passwordReset} element={<ResetPasswordPage />} />
          <Route path="*" element={<Navigate to={routes.login} />} />
        </>
      );
    }

    if (isAuthenticated && currentUser?.role === Roles.ADMIN) {
      return (
        <>
          <Route
            path={routes.admin}
            element={<Navigate replace to={routes.children.list} />}
          />
          <Route path={routes.users.create} element={<UserCreatePage />} />
          <Route path={routes.users.edit} element={<UserEditPage />} />
          <Route path={routes.users.list} element={<UsersListPage />} />
          <Route path={routes.branches.edit} element={<BranchEditPage />} />
          <Route path={routes.branches.list} element={<BranchListPage />} />
          <Route
            path={routes.workTimes.create}
            element={<WorkTimeCreatePage />}
          />
          <Route path={routes.workTimes.edit} element={<WorkTimeEditPage />} />
          <Route path={routes.workTimes.list} element={<WorkTimeListPage />} />
          <Route
            path={routes.workTimes.calendar}
            element={<WorkTimeCalendarPage />}
          />
          <Route
            path={routes.employees.create}
            element={<EmployeeCreatePage />}
          />
          <Route path={routes.employees.edit} element={<EmployeeEditPage />} />
          <Route path={routes.employees.list} element={<EmployeeListPage />} />
          <Route path={routes.children.create} element={<ChildCreatePage />} />
          <Route path={routes.children.edit} element={<ChildEditPage />} />
          <Route path={routes.children.list} element={<ChildListPage />} />
          <Route
            path={routes.additionalService.create}
            element={<AdditionalServiceCreatePage />}
          />
          <Route
            path={routes.additionalService.edit}
            element={<AdditionalServiceEditPage />}
          />
          <Route
            path={routes.additionalService.list}
            element={<AdditionalServiceListPage />}
          />
          <Route
            path={routes.attendancePackages.list}
            element={<AttendancePackageListPage />}
          />
          <Route path={routes.attendances} element={<AttendancesPage />} />
          <Route path={routes.achievements} element={<AchievementsPage />} />
          <Route path={routes.payments} element={<PaymentListPage />} />
          <Route
            path={routes.subscribedTimes}
            element={<SubscribedTimeListPage />}
          />
          <Route path={routes.timeOffs} element={<TimeOffListPage />} />
          <Route path={routes.translations} element={<TranslationsPage />} />
          <Route path={routes.languages} element={<LanguagesListPage />} />
          <Route path="*" element={<Navigate to={routes.admin} />} />
        </>
      );
    }

    if (isAuthenticated && currentUser?.role === Roles.MANAGER) {
      return (
        <>
          <Route
            path={routes.admin}
            element={<Navigate replace to={routes.children.list} />}
          />
          <Route path={routes.employees.list} element={<EmployeeListPage />} />
          <Route path={routes.children.create} element={<ChildCreatePage />} />
          <Route path={routes.children.edit} element={<ChildEditPage />} />
          <Route path={routes.children.list} element={<ChildListPage />} />
          <Route path={routes.attendances} element={<AttendancesPage />} />
          <Route path={routes.achievements} element={<AchievementsPage />} />
          <Route path={routes.payments} element={<PaymentListPage />} />
          <Route path={routes.workTimes.list} element={<WorkTimeListPage />} />
          <Route
            path={routes.workTimes.calendar}
            element={<WorkTimeCalendarPage />}
          />
          <Route
            path={routes.subscribedTimes}
            element={<SubscribedTimeListPage />}
          />
          <Route path="*" element={<Navigate to={routes.admin} />} />
        </>
      );
    }

    if (isAuthenticated && currentUser?.role === Roles.PARENT) {
      return (
        <>
          <Route
            path={routes.admin}
            element={
              <Navigate
                replace
                to={
                  currentUser.parent?.branch
                    ? routes.parent.children.list
                    : routes.parent.profile
                }
              />
            }
          />
          <Route path={routes.parent.profile} element={<ProfilePage />} />
          <Route
            path={routes.parent.children.list}
            element={<ParentChildListPage />}
          />
          <Route
            path={routes.parent.children.create}
            element={<ParentChildCreatePage />}
          />
          <Route
            path={routes.parent.children.edit}
            element={<ParentChildEditPage />}
          />
          <Route
            path={routes.parent.achievements}
            element={<ParentAchievementsPage />}
          />
          <Route path={routes.parent.purchase} element={<PurchasePage />} />
          <Route
            path={routes.parent.payments}
            element={<ParentPaymentListPage />}
          />
          <Route
            path={routes.parent.attendances}
            element={<ParentAttendanceListPage />}
          />
          <Route
            path={routes.parent.wantedTimes}
            element={<WantedTimeListPage />}
          />
          <Route path="*" element={<Navigate to={routes.admin} />} />
        </>
      );
    }

    if (isAuthenticated && currentUser?.role === Roles.EMPLOYEE) {
      return (
        <>
          <Route
            path={routes.admin}
            element={<Navigate replace to={routes.attendances} />}
          />
          <Route path={routes.attendances} element={<AttendancesPage />} />
          <Route path={routes.achievements} element={<TestingFormPage />} />
          <Route path="*" element={<Navigate to={routes.attendances} />} />
        </>
      );
    }

    return <></>;
  };

  return (
    <BrowserRouter basename="/">
      {isInitCompleted && !isCurrentUserLoading && language ? (
        <IntlProvider
          messages={mappedTranslations}
          locale={language?.locale ?? DEFAULT_LOCALE}
          defaultLocale="en"
        >
          <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onReset={() => {
              window.location.reload();
            }}
          >
            <Suspense fallback={<Loader isLoading isFullScreen />}>
              <Layout isAuthenticated={isAuthenticated}>
                <Routes>{getRoutes()}</Routes>
              </Layout>
            </Suspense>
          </ErrorBoundary>
        </IntlProvider>
      ) : (
        <Loader isLoading isFullScreen />
      )}
    </BrowserRouter>
  );
};

const mapStateToProps = (state: StoreState) => ({
  isInitCompleted: state.auth.isInitCompleted,
  isAuthenticated: state.auth.isAuthenticated,
  isCurrentUserLoading:
    state.user.currentUserLoading && !state.user.currentUser,
  refreshTokenLoading: state.auth.refreshTokenLoading,
  jwtToken: state.auth.jwtToken,
  lastActionAt: state.auth.lastStoreActionAt,
  language: state.language.language,
  selectedLocale: state.auth.selectedLocale,
  currentUser: state.user.currentUser,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onTryAutoSignup: () => dispatch(authService.authCheckState()),
  onFetchCurrentUser: () => dispatch(userService.fetchCurrentUser()),
  onRefreshToken: () => dispatch(authService.refreshToken()),
  onLanguageFetch: (locale: string) =>
    dispatch(languageService.fetchLanguage(locale)),
  onLogout: () => dispatch(logout()),
  onSelectLocale: (locale: Locale) => dispatch(selectLocale(locale)),
  onLanguagesInit: () => dispatch(languageService.fetchLanguages()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Router);
