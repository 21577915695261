import * as actionTypes from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { Payment, PaymentStatus } from '../../domain/Payment';
import { ListResults } from '../../common/List/List';
import { PaymentActionTypes } from './actionTypes';

export type PaymentStateType = {
  paymentsList: ListResults<Payment> | null;
  paymentsLoading: boolean;
  paymentsError: HttpError;
  paymentsUpdateNeeded: boolean;
  childPaymentsList: ListResults<Payment> | null;
  childPaymentsLoading: boolean;
  childPaymentsError: HttpError;
  parentPaymentsList: ListResults<Payment> | null;
  parentPaymentsLoading: boolean;
  parentPaymentsError: HttpError;
  exportPayments: Payment[] | null;
  exportPaymentsLoading: boolean;
  exportPaymentsError: HttpError;
  deletePaymentError: HttpError;
  deletePaymentLoading: boolean;
  paymentStatusLoading: boolean;
  paymentStatusError: HttpError;
  paymentStatus: PaymentStatus | null;
  movePaymentAttendancesLoading: boolean;
  movePaymentAttendancesError: HttpError;
};

export type PaymentActionType = PaymentStateType & {
  type: PaymentActionTypes;
};

export const initialState: PaymentStateType = {
  paymentsList: null,
  paymentsError: null,
  paymentsLoading: false,
  childPaymentsError: null,
  childPaymentsLoading: false,
  childPaymentsList: null,
  exportPayments: null,
  exportPaymentsLoading: false,
  exportPaymentsError: null,
  paymentsUpdateNeeded: false,
  deletePaymentError: null,
  deletePaymentLoading: false,
  paymentStatus: null,
  paymentStatusError: null,
  paymentStatusLoading: false,
  parentPaymentsError: null,
  parentPaymentsList: null,
  parentPaymentsLoading: false,
  movePaymentAttendancesError: null,
  movePaymentAttendancesLoading: false,
};

const fetchPaymentsStart = (state: PaymentStateType): PaymentStateType => ({
  ...state,
  paymentsLoading: true,
  paymentsUpdateNeeded: false,
});

const fetchPaymentsSuccess = (
  state: PaymentStateType,
  action: PaymentActionType,
): PaymentStateType => ({
  ...state,
  paymentsLoading: false,
  paymentsError: null,
  paymentsList: action.paymentsList,
});

const fetchPaymentsFail = (
  state: PaymentStateType,
  action: PaymentActionType,
): PaymentStateType => ({
  ...state,
  paymentsLoading: false,
  paymentsError: action.paymentsError,
});

const fetchPaymentStatusStart = (
  state: PaymentStateType,
): PaymentStateType => ({
  ...state,
  paymentStatusLoading: true,
});

const fetchPaymentStatusSuccess = (
  state: PaymentStateType,
  action: PaymentActionType,
): PaymentStateType => ({
  ...state,
  paymentStatusLoading: false,
  paymentStatusError: null,
  paymentStatus: action.paymentStatus,
});

const fetchPaymentStatusFail = (
  state: PaymentStateType,
  action: PaymentActionType,
): PaymentStateType => ({
  ...state,
  paymentStatusLoading: false,
  paymentStatusError: action.paymentStatusError,
});

const fetchChildPaymentsStart = (
  state: PaymentStateType,
): PaymentStateType => ({
  ...state,
  childPaymentsLoading: true,
  paymentsUpdateNeeded: false,
});

const fetchChildPaymentsSuccess = (
  state: PaymentStateType,
  action: PaymentActionType,
): PaymentStateType => ({
  ...state,
  childPaymentsLoading: false,
  childPaymentsError: null,
  childPaymentsList: action.childPaymentsList,
});

const fetchChildPaymentsFail = (
  state: PaymentStateType,
  action: PaymentActionType,
): PaymentStateType => ({
  ...state,
  childPaymentsLoading: false,
  childPaymentsError: action.childPaymentsError,
});

const fetchParentPaymentsStart = (
  state: PaymentStateType,
): PaymentStateType => ({
  ...state,
  parentPaymentsLoading: true,
  paymentsUpdateNeeded: false,
});

const fetchParentPaymentsSuccess = (
  state: PaymentStateType,
  action: PaymentActionType,
): PaymentStateType => ({
  ...state,
  parentPaymentsLoading: false,
  parentPaymentsError: null,
  parentPaymentsList: action.parentPaymentsList,
});

const fetchParentPaymentsFail = (
  state: PaymentStateType,
  action: PaymentActionType,
): PaymentStateType => ({
  ...state,
  parentPaymentsLoading: false,
  parentPaymentsError: action.parentPaymentsError,
});

const movePaymentAttendancesStart = (
  state: PaymentStateType,
): PaymentStateType => ({
  ...state,
  movePaymentAttendancesLoading: true,
});

const movePaymentAttendancesSuccess = (
  state: PaymentStateType,
): PaymentStateType => ({
  ...state,
  movePaymentAttendancesLoading: false,
  movePaymentAttendancesError: null,
  paymentsUpdateNeeded: true,
});

const movePaymentAttendancesFail = (
  state: PaymentStateType,
  action: PaymentActionType,
): PaymentStateType => ({
  ...state,
  movePaymentAttendancesLoading: false,
  movePaymentAttendancesError: action.movePaymentAttendancesError,
});

const exportPaymentsStart = (state: PaymentStateType): PaymentStateType => ({
  ...state,
  exportPaymentsLoading: true,
});

const exportPaymentsSuccess = (
  state: PaymentStateType,
  action: PaymentActionType,
): PaymentStateType => ({
  ...state,
  exportPaymentsLoading: false,
  exportPaymentsError: null,
  exportPayments: action.exportPayments,
});

const exportPaymentsFail = (
  state: PaymentStateType,
  action: PaymentActionType,
): PaymentStateType => ({
  ...state,
  exportPaymentsLoading: false,
  exportPaymentsError: action.exportPaymentsError,
});

const deletePaymentStart = (state: PaymentStateType): PaymentStateType => ({
  ...state,
  deletePaymentLoading: true,
});

const deletePaymentSuccess = (state: PaymentStateType): PaymentStateType => ({
  ...state,
  deletePaymentLoading: false,
  deletePaymentError: null,
  paymentsUpdateNeeded: true,
});

const deletePaymentFail = (
  state: PaymentStateType,
  action: PaymentActionType,
): PaymentStateType => ({
  ...state,
  deletePaymentLoading: false,
  deletePaymentError: action.deletePaymentError,
});

const updateChildPaymentList = (state: PaymentStateType): PaymentStateType => ({
  ...state,
  paymentsUpdateNeeded: true,
});

const resetExportPayments = (state: PaymentStateType): PaymentStateType => ({
  ...state,
  exportPaymentsLoading: false,
  exportPayments: null,
  exportPaymentsError: null,
});

const resetPaymentStore = (): PaymentStateType => ({
  ...initialState,
});

const completelyResetPaymentStore = (): PaymentStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: PaymentActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_PAYMENTS_START:
      return fetchPaymentsStart(state);
    case actionTypes.FETCH_PAYMENTS_SUCCESS:
      return fetchPaymentsSuccess(state, action);
    case actionTypes.FETCH_PAYMENTS_FAIL:
      return fetchPaymentsFail(state, action);
    case actionTypes.FETCH_PAYMENT_STATUS_START:
      return fetchPaymentStatusStart(state);
    case actionTypes.FETCH_PAYMENT_STATUS_SUCCESS:
      return fetchPaymentStatusSuccess(state, action);
    case actionTypes.FETCH_PAYMENT_STATUS_FAIL:
      return fetchPaymentStatusFail(state, action);
    case actionTypes.FETCH_CHILD_PAYMENTS_START:
      return fetchChildPaymentsStart(state);
    case actionTypes.FETCH_CHILD_PAYMENTS_SUCCESS:
      return fetchChildPaymentsSuccess(state, action);
    case actionTypes.FETCH_CHILD_PAYMENTS_FAIL:
      return fetchChildPaymentsFail(state, action);
    case actionTypes.FETCH_PARENT_PAYMENTS_START:
      return fetchParentPaymentsStart(state);
    case actionTypes.FETCH_PARENT_PAYMENTS_SUCCESS:
      return fetchParentPaymentsSuccess(state, action);
    case actionTypes.FETCH_PARENT_PAYMENTS_FAIL:
      return fetchParentPaymentsFail(state, action);
    case actionTypes.EXPORT_PAYMENTS_START:
      return exportPaymentsStart(state);
    case actionTypes.EXPORT_PAYMENTS_SUCCESS:
      return exportPaymentsSuccess(state, action);
    case actionTypes.EXPORT_PAYMENTS_FAIL:
      return exportPaymentsFail(state, action);
    case actionTypes.RESET_EXPORT_PAYMENTS:
      return resetExportPayments(state);
    case actionTypes.DELETE_PAYMENT_START:
      return deletePaymentStart(state);
    case actionTypes.DELETE_PAYMENT_SUCCESS:
      return deletePaymentSuccess(state);
    case actionTypes.DELETE_PAYMENT_FAIL:
      return deletePaymentFail(state, action);
    case actionTypes.UPDATE_CHILD_PAYMENT_LIST:
      return updateChildPaymentList(state);
    case actionTypes.MOVE_PAYMENT_ATTENDANCES_START:
      return movePaymentAttendancesStart(state);
    case actionTypes.MOVE_PAYMENT_ATTENDANCES_SUCCESS:
      return movePaymentAttendancesSuccess(state);
    case actionTypes.MOVE_PAYMENT_ATTENDANCES_FAIL:
      return movePaymentAttendancesFail(state, action);
    case actionTypes.RESET_PAYMENT_STORE:
      return resetPaymentStore();
    case actionTypes.LOGOUT:
      return completelyResetPaymentStore();
    default:
      return state;
  }
};

export default reducer;
