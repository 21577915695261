import * as actionTypes from './actionTypes';
import { TestingResultActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { TestingResult } from '../../domain/TestingResult';
import { ListResults } from '../../common/List/List';

export type TestingResultStateType = {
  testingResultsList: ListResults<TestingResult> | null;
  testingResultsLoading: boolean;
  testingResultsError: HttpError;
  testingResultsUpdateNeeded: boolean;
  downloadTestingResultLoading: boolean;
  downloadTestingResultError: HttpError;
};

export type TestingResultActionType = TestingResultStateType & {
  type: TestingResultActionTypes;
};

export const initialState: TestingResultStateType = {
  testingResultsUpdateNeeded: false,
  testingResultsList: null,
  testingResultsError: null,
  testingResultsLoading: false,
  downloadTestingResultError: null,
  downloadTestingResultLoading: false,
};

const fetchTestingResultsStart = (
  state: TestingResultStateType,
): TestingResultStateType => ({
  ...state,
  testingResultsLoading: true,
  testingResultsUpdateNeeded: false,
});

const fetchTestingResultsSuccess = (
  state: TestingResultStateType,
  action: TestingResultActionType,
): TestingResultStateType => ({
  ...state,
  testingResultsLoading: false,
  testingResultsError: null,
  testingResultsList: action.testingResultsList,
});

const fetchTestingResultsFail = (
  state: TestingResultStateType,
  action: TestingResultActionType,
): TestingResultStateType => ({
  ...state,
  testingResultsLoading: false,
  testingResultsError: action.testingResultsError,
});

const downloadTestingResultStart = (
  state: TestingResultStateType,
): TestingResultStateType => ({
  ...state,
  downloadTestingResultLoading: true,
});

const downloadTestingResultSuccess = (
  state: TestingResultStateType,
): TestingResultStateType => ({
  ...state,
  downloadTestingResultLoading: false,
  downloadTestingResultError: null,
});

const downloadTestingResultFail = (
  state: TestingResultStateType,
  action: TestingResultActionType,
): TestingResultStateType => ({
  ...state,
  downloadTestingResultLoading: false,
  downloadTestingResultError: action.downloadTestingResultError,
});

const resetTestingResultStore = (): TestingResultStateType => ({
  ...initialState,
});

const completelyResetTestingResultStore = (): TestingResultStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: TestingResultActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_TESTING_RESULTS_START:
      return fetchTestingResultsStart(state);
    case actionTypes.FETCH_TESTING_RESULTS_SUCCESS:
      return fetchTestingResultsSuccess(state, action);
    case actionTypes.FETCH_TESTING_RESULTS_FAIL:
      return fetchTestingResultsFail(state, action);
    case actionTypes.DOWNLOAD_TESTING_RESULT_START:
      return downloadTestingResultStart(state);
    case actionTypes.DOWNLOAD_TESTING_RESULT_SUCCESS:
      return downloadTestingResultSuccess(state);
    case actionTypes.DOWNLOAD_TESTING_RESULT_FAIL:
      return downloadTestingResultFail(state, action);
    case actionTypes.RESET_TESTING_RESULT_STORE:
      return resetTestingResultStore();
    case actionTypes.LOGOUT:
      return completelyResetTestingResultStore();
    default:
      return state;
  }
};

export default reducer;
