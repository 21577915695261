import * as actionTypes from './actionTypes';
import { WantedTimeActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { WantedTime } from '../../domain/WantedTime';
import { ListResults } from '../../common/List/List';

export type WantedTimeStateType = {
  wantedTimeCreateLoading: boolean;
  wantedTimeCreateError: HttpError;
  wantedTimeCreateSuccess: boolean;
  createdWantedTime: WantedTime | null;
  wantedTimeDeleteLoading: boolean;
  wantedTimeDeleteError: HttpError;
  wantedTimeDeleteSuccess: boolean;
  wantedTimesList: ListResults<WantedTime> | null;
  wantedTimesLoading: boolean;
  wantedTimesError: HttpError;
  wantedTimesUpdateNeeded: boolean;
  subscribedTimes: ListResults<WantedTime> | null;
  subscribedTimesLoading: boolean;
  subscribedTimesError: HttpError;
};

export type WantedTimeActionType = WantedTimeStateType & {
  type: WantedTimeActionTypes;
};

export const initialState: WantedTimeStateType = {
  wantedTimeCreateLoading: false,
  wantedTimeCreateError: null,
  wantedTimeCreateSuccess: false,
  createdWantedTime: null,
  wantedTimeDeleteLoading: false,
  wantedTimeDeleteError: null,
  wantedTimeDeleteSuccess: false,
  wantedTimesUpdateNeeded: false,
  wantedTimesList: null,
  wantedTimesError: null,
  wantedTimesLoading: false,
  subscribedTimes: null,
  subscribedTimesError: null,
  subscribedTimesLoading: false,
};

const fetchWantedTimesStart = (
  state: WantedTimeStateType,
): WantedTimeStateType => ({
  ...state,
  wantedTimesLoading: true,
  wantedTimesUpdateNeeded: false,
});

const fetchWantedTimesSuccess = (
  state: WantedTimeStateType,
  action: WantedTimeActionType,
): WantedTimeStateType => ({
  ...state,
  wantedTimesLoading: false,
  wantedTimesError: null,
  wantedTimesList: action.wantedTimesList,
});

const fetchWantedTimesFail = (
  state: WantedTimeStateType,
  action: WantedTimeActionType,
): WantedTimeStateType => ({
  ...state,
  wantedTimesLoading: false,
  wantedTimesError: action.wantedTimesError,
});

const fetchSubscribedTimesStart = (
  state: WantedTimeStateType,
): WantedTimeStateType => ({
  ...state,
  subscribedTimesLoading: true,
});

const fetchSubscribedTimesSuccess = (
  state: WantedTimeStateType,
  action: WantedTimeActionType,
): WantedTimeStateType => ({
  ...state,
  subscribedTimesLoading: false,
  subscribedTimesError: null,
  subscribedTimes: action.subscribedTimes,
});

const fetchSubscribedTimesFail = (
  state: WantedTimeStateType,
  action: WantedTimeActionType,
): WantedTimeStateType => ({
  ...state,
  subscribedTimesLoading: false,
  subscribedTimesError: action.subscribedTimesError,
});

const createWantedTimeStart = (
  state: WantedTimeStateType,
): WantedTimeStateType => ({
  ...state,
  wantedTimeCreateLoading: true,
});

const createWantedTimeSuccess = (
  state: WantedTimeStateType,
  action: WantedTimeActionType,
): WantedTimeStateType => ({
  ...state,
  wantedTimeCreateLoading: false,
  wantedTimeCreateError: null,
  wantedTimeCreateSuccess: true,
  createdWantedTime: action.createdWantedTime,
  wantedTimesUpdateNeeded: true,
});

const createWantedTimeFail = (
  state: WantedTimeStateType,
  action: WantedTimeActionType,
): WantedTimeStateType => ({
  ...state,
  wantedTimeCreateLoading: false,
  wantedTimeCreateError: action.wantedTimeCreateError,
});

const deleteWantedTimeStart = (
  state: WantedTimeStateType,
): WantedTimeStateType => ({
  ...state,
  wantedTimeDeleteLoading: true,
});

const deleteWantedTimeSuccess = (
  state: WantedTimeStateType,
): WantedTimeStateType => ({
  ...state,
  wantedTimeDeleteLoading: false,
  wantedTimeDeleteError: null,
  wantedTimeDeleteSuccess: true,
  wantedTimesUpdateNeeded: true,
});

const deleteWantedTimeFail = (
  state: WantedTimeStateType,
  action: WantedTimeActionType,
): WantedTimeStateType => ({
  ...state,
  wantedTimeDeleteLoading: false,
  wantedTimeDeleteError: action.wantedTimeDeleteError,
});

const resetWantedTimeFormStore = (
  state: WantedTimeStateType,
): WantedTimeStateType => ({
  ...state,
  wantedTimeCreateError: null,
});

const resetWantedTimeStore = (): WantedTimeStateType => ({
  ...initialState,
});

const completelyResetWantedTimeStore = (): WantedTimeStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: WantedTimeActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_WANTED_TIMES_START:
      return fetchWantedTimesStart(state);
    case actionTypes.FETCH_WANTED_TIMES_SUCCESS:
      return fetchWantedTimesSuccess(state, action);
    case actionTypes.FETCH_WANTED_TIMES_FAIL:
      return fetchWantedTimesFail(state, action);
    case actionTypes.FETCH_SUBSCRIBED_TIMES_START:
      return fetchSubscribedTimesStart(state);
    case actionTypes.FETCH_SUBSCRIBED_TIMES_SUCCESS:
      return fetchSubscribedTimesSuccess(state, action);
    case actionTypes.FETCH_SUBSCRIBED_TIMES_FAIL:
      return fetchSubscribedTimesFail(state, action);
    case actionTypes.CREATE_WANTED_TIME_START:
      return createWantedTimeStart(state);
    case actionTypes.CREATE_WANTED_TIME_SUCCESS:
      return createWantedTimeSuccess(state, action);
    case actionTypes.CREATE_WANTED_TIME_FAIL:
      return createWantedTimeFail(state, action);
    case actionTypes.DELETE_WANTED_TIME_START:
      return deleteWantedTimeStart(state);
    case actionTypes.DELETE_WANTED_TIME_SUCCESS:
      return deleteWantedTimeSuccess(state);
    case actionTypes.DELETE_WANTED_TIME_FAIL:
      return deleteWantedTimeFail(state, action);
    case actionTypes.RESET_WANTED_TIME_FORM_STORE:
      return resetWantedTimeFormStore(state);
    case actionTypes.RESET_WANTED_TIME_STORE:
      return resetWantedTimeStore();
    case actionTypes.LOGOUT:
      return completelyResetWantedTimeStore();
    default:
      return state;
  }
};

export default reducer;
