export const FETCH_TESTING_RESULTS_START = 'FETCH_TESTING_RESULTS_START';
export const FETCH_TESTING_RESULTS_SUCCESS = 'FETCH_TESTING_RESULTS_SUCCESS';
export const FETCH_TESTING_RESULTS_FAIL = 'FETCH_TESTING_RESULTS_FAIL';

export const DOWNLOAD_TESTING_RESULT_START = 'DOWNLOAD_TESTING_RESULT_START';
export const DOWNLOAD_TESTING_RESULT_SUCCESS =
  'DOWNLOAD_TESTING_RESULT_SUCCESS';
export const DOWNLOAD_TESTING_RESULT_FAIL = 'DOWNLOAD_TESTING_RESULT_FAIL';

export const RESET_TESTING_RESULT_STORE = 'RESET_TESTING_RESULT_STORE';

export const LOGOUT = 'LOGOUT';

export type TestingResultActionTypes =
  | typeof FETCH_TESTING_RESULTS_START
  | typeof FETCH_TESTING_RESULTS_SUCCESS
  | typeof FETCH_TESTING_RESULTS_FAIL
  | typeof DOWNLOAD_TESTING_RESULT_START
  | typeof DOWNLOAD_TESTING_RESULT_SUCCESS
  | typeof DOWNLOAD_TESTING_RESULT_FAIL
  | typeof RESET_TESTING_RESULT_STORE
  | typeof LOGOUT;
